<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Documentos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Documentos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_documento_export"
                        v-if="$store.getters.can('admin.documentos.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="abrirModal('Agregar', null)"
                        data-toggle="modal"
                        data-target="#Modal_docs"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Descripcion<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.descripcion"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo de Documento
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_doc"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.tipo_docs"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Vencimiento</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in documentos.data" :key="item.id">
                      <td>{{ item.descripcion }}</td>
                      <td>{{ item.tipo_doc }}</td>
                      <td>
                        <span
                          class="badge"
                          v-bind:class="[
                            item.vencimiento == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ item.vencimiento == 1 ? "SI" : "NO" }}
                        </span>
                      </td>
                      <td>
                        <span
                          class="badge"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#Modal_docs"
                            v-if="$store.getters.can('admin.documentos.show')"
                            @click="abrirModal('Editar', item.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(item.id)"
                            v-if="$store.getters.can('admin.documentos.delete')"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="documentos.total">
                  <p>
                    Mostrando del <b>{{ documentos.from }}</b> al
                    <b>{{ documentos.to }}</b> de un total:
                    <b>{{ documentos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="documentos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="modal fade" id="Modal_docs">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="descripcion">Descripción</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="descripcion"
                    placeholder="descripcion"
                    v-model="form.descripcion"
                    :class="
                      $v.form.descripcion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="tipo_doc">Tipo Documento</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.tipo_doc"
                    :class="
                      $v.form.tipo_doc.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_doc in listasForms.tipo_docs"
                      :key="tipo_doc.numeracion"
                      :value="tipo_doc.numeracion"
                    >
                      {{ tipo_doc.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="estado">Estado</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.estado"
                    :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="estado in listasForms.estados"
                      :key="estado.numeracion"
                      :value="estado.numeracion"
                    >
                      {{ estado.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="vencimiento"
                      v-model="form.vencimiento"
                    />
                    <label class="custom-control-label" for="vencimiento"
                      >Vencimiento</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <DocumentoExport ref="DocumentoExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required, minLength } from "vuelidate/lib/validators";
import DocumentoExport from "./DocumentoExport";
export default {
  name: "Documentos",
  components: {
    pagination,
    DocumentoExport,
  },
  data() {
    return {
      filtros: {
        descripcion: null,
        tipo_doc: null,
      },
      form: {
        vencimiento: false,
      },
      documentos: {},
      listasForms: {
        estados: [],
        tipo_docs: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },
  validations: {
    form: {
      descripcion: {
        required,
      },
      tipo_doc: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/documentos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.documentos = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposDocs() {
      axios.get("/api/lista/3").then((response) => {
        this.listasForms.tipo_docs = response.data;
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios
          .post("/api/admin/documentos", this.form)
          .then((response) => {
            this.form = {};
            this.getIndex();
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("1062 Duplicate entry")) {
                this.$swal({
                  icon: "error",
                  title:
                    "La descripción ya se encuentra creada en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la acción nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.cargando = false;
          });
      }
    },

    abrirModal(accion, idDocumento) {
      this.getTiposDocs();
      this.getEstados();
      this.modal.accion = accion;
      this.modal.title = accion + " Documento";
      if (accion == "Editar") {
        this.show(idDocumento);
      } else {
        this.form = {};
      }
    },

    destroy(idDocumento) {
      this.$swal({
        title: "Esta seguro de eliminar este Documento?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/documentos/" + idDocumento).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el Documento exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    show(idDocumento) {
      axios.get("/api/admin/documentos/" + idDocumento).then((response) => {
        this.form = response.data;
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getTiposDocs();
    this.getEstados();
    this.getMsg();
  },
};
</script>
